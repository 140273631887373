$big: 1600px;
$mid: 1400px;
$sm: 1200px;
$mobile: 1024px;
@mixin dvMax ($screen){
  @media screen and (max-width: $screen) {
    @content;
  }
}

@mixin dv ($screen){
  @media screen and (min-width: $screen) {
    @content;
  }
}
@mixin dvs ($dvscreen){
  @media screen and ($dvscreen) {
    @content;
  }
}

@mixin cfont ($weight) {
  // font-family: 'Golos Text', sans-serif;
  font-family: 'Noto Sans Display';
  // font-family: 'Roboto', sans-serif;
  font-weight: $weight
}
$fontFace: Poppins-Medium, Poppins;